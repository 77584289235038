<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="保险类别">
          <l-select v-model="condition.insuranceType" :data="$constant.insuranceTypeList"></l-select>
        </el-form-item>
        <el-form-item label="有效期到期区间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="车牌号/保险公司"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carId)">{{ scope.row.carNo }}</el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import insuranceAddoredit from './insurance_addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarInsurance',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', slot: 'carNo' },
          { label: '保险类别', prop: 'insuranceTypeText' },
          { label: '保单号', prop: 'orderCode' },
          { label: '保险公司', prop: 'companyName' },
          { label: '保额(万元)', prop: 'coverage' },
          { label: '保费', prop: 'fee' },
          { label: '有效期开始', prop: 'startDate', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '有效期结束', prop: 'endDate', filter: (a, data) => this.$lgh.limitTo(a, 10) }
        ],
        operation: {
          width: '220',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增', click: this.add },
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        insuranceType: '',
        dealState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/car/insurance/pagelist', ['enterpriseCode', this.condition])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: insuranceAddoredit,
        title: '新增保险记录',
        data: {
          departList: this.departList,
          roleList: this.roleList,
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            createUserId: this.userInfo.userId,
            createUserName: this.userInfo.fullName
          }
        }
      })
      this.$lgh.toastSuccess('新建保险记录成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: insuranceAddoredit,
        title: '修改保险记录',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改保险记录成功')
      this.getPageData()
    }
  }
}
</script>
